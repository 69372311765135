import React from 'react'
import Layout from '../components/Layout'
import { withIntl } from '../i18n'

const SuccessPage = (props) => (
    <Layout>
        <section id="one">
            <div className="inner">
                <header className="major">
                    <h1>Thank You for your message</h1>
                </header>
            </div>
        </section>
    </Layout>
)

export default withIntl(SuccessPage)